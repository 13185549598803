'use client'

import { useEffect, useRef } from 'react'
import gsap from 'gsap'

import { useSectionThemeContext } from '@/contexts/SectionTheme.context'

import { Col, Container, Row } from '@/components/atoms/GridSystem'
import { ButtonForwardingRef } from '@/components/atoms/Button/Button.component'

import * as S from './Footer.styles'

const Footer = () => {
  const colorScheme = useSectionThemeContext()
  const buttonsRefs = useRef([])
  const hasAnimatedRef = useRef(false)

  useEffect(() => {
    if (hasAnimatedRef.current) return // Prevent double rendering

    gsap.from(buttonsRefs.current, {
      opacity: 0,
      y: '100%',
      stagger: 0.5,
      duration: 2,
      ease: 'expo.out',
    })

    hasAnimatedRef.current = true // Mark as animated
  }, [])

  return (
    <S.Footer colorScheme={colorScheme}>
      <Container>
        <Row>
          <Col
            xs={12}
            md={2}
            $offset={{ xs: 0, md: 2 }}
            $direction='row'
            style={{ overflow: 'hidden' }}
          >
            <ButtonForwardingRef
              label='LinkedIn'
              href='https://www.linkedin.com/in/stefano-iachetta'
              target='_blank'
              variant='secondary'
              ref={(el) => buttonsRefs.current.push(el)}
            />
          </Col>
          <Col
            xs={12}
            md={2}
            $offset={{ xs: 0, md: 1 }}
            $direction='row'
            justify='center'
            style={{ overflow: 'hidden' }}
          >
            <ButtonForwardingRef
              label='GitHub'
              href='https://github.com/stfn00'
              target='_blank'
              variant='secondary'
              ref={(el) => buttonsRefs.current.push(el)}
            />
          </Col>
          <Col
            xs={12}
            md={2}
            $offset={{ xs: 0, md: 1 }}
            $direction='row'
            justify='flex-end'
            style={{ overflow: 'hidden' }}
          >
            <ButtonForwardingRef
              label='Instagram'
              href='https://www.instagram.com/stfn.exe'
              target='_blank'
              variant='secondary'
              ref={(el) => buttonsRefs.current.push(el)}
            />
          </Col>
        </Row>
      </Container>
    </S.Footer>
  )
}

export default Footer
