import styled from 'styled-components'

import { Container } from '@/components/atoms/GridSystem'

export const GridPlaceholder = styled.div`
  width: 100dvw;
  height: 100dvh;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
`

export const GridPlaceholderContainer = styled(Container)`
  height: 100%;
`

export const GridPlaceholderLoader = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: ${({ theme }) => theme.rem(24)};

  ${({ theme }) => theme.mediaQuery.lg(`
    grid-template-columns: repeat(12, 1fr);
  `)}

  .grid-placeholder__loader-col {
    content: '';
    height: 0;
    display: inline-block;
    border-right: 1px solid ${({ theme }) => theme.colors.secondary.surface.dark2};
    border-left: 1px solid ${({ theme }) => theme.colors.secondary.surface.dark2};
    opacity: 0.5;
  }
`
