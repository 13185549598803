import styled from 'styled-components'

export const HeaderTitle = styled.div`
  letter-spacing: 3px;
  font-weight: 800;
`

export const Header = styled.header`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: ${({ theme }) => theme.rem(16, 0)};
  background-color: ${({ theme, colorScheme }) => theme.colors[colorScheme].surface.default};
  z-index: ${({ theme }) => theme.layers.header};
  color: ${({ theme, colorScheme }) => theme.colors[colorScheme].surface.dark2};
`
