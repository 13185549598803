'use client'

import { useEffect, useRef } from 'react'
import gsap from 'gsap'

import { useSectionThemeContext } from '@/contexts/SectionTheme.context'

import { Col, Container, Row } from '@/components/atoms/GridSystem'
import { TypographyForwardingRef } from '@/components/atoms/Typography/Typography.component'

import * as S from './Header.styles'

const Header = () => {
  const colorScheme = useSectionThemeContext()
  const textsRefs = useRef([])
  const hasAnimatedRef = useRef(false)

  useEffect(() => {
    if (hasAnimatedRef.current) return // Prevent double rendering

    gsap.from(textsRefs.current, {
      opacity: 0,
      y: '100%',
      stagger: 0.5,
      duration: 2,
      ease: 'expo.out',
    })

    hasAnimatedRef.current = true // Mark as animated
  }, [])

  return (
    <S.Header colorScheme={colorScheme}>
      <Container>
        <Row>
          <Col
            xs={6}
            md={4}
            $direction='row'
            $order={{ xs: '1', md: '0' }}
            style={{ overflow: 'hidden' }}
          >
            <TypographyForwardingRef content='2024:v1' ref={(el) => textsRefs.current.push(el)} />
          </Col>
          <Col
            xs={12}
            md={4}
            $direction='row'
            justify='center'
            $order={{ xs: '0', md: '1' }}
            style={{ overflow: 'hidden' }}
          >
            <S.HeaderTitle
              as={TypographyForwardingRef}
              content='TEMPORARY PORTFOLIO'
              ref={(el) => textsRefs.current.push(el)}
            />
          </Col>
          <Col
            xs={6}
            md={4}
            $direction='row'
            justify='flex-end'
            $order={{ xs: '2', md: '2' }}
            style={{ overflow: 'hidden' }}
          >
            <TypographyForwardingRef content='Based in Italy' ref={(el) => textsRefs.current.push(el)} />
          </Col>
        </Row>
      </Container>
    </S.Header>
  )
}

export default Header
