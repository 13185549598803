import styled from 'styled-components'

export const Footer = styled.footer`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: ${({ theme }) => theme.rem(16, 0)};
  background-color: ${({ theme, colorScheme }) => theme.colors[colorScheme].surface.default};
  z-index: ${({ theme }) => theme.layers.footer};
`
