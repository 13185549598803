import { useEffect, useRef } from 'react'

import gsap from 'gsap'

import * as S from './GridPlaceholder.styles'

const GridPlaceholder = () => {
  const gridLoaderRef = useRef(null)
  const hasAnimatedRef = useRef(false)

  useEffect(() => {
    if (hasAnimatedRef.current) return // Prevent double rendering

    const gridLoader = gridLoaderRef.current

    if (gridLoader) {
      const columnCount = window
        .getComputedStyle(gridLoader)
        .getPropertyValue('grid-template-columns')
        .split(' ').length

      const animationTimeline = gsap.timeline()

      for (let i = 0; i < columnCount; i++) {
        const columnDiv = document.createElement('div')
        columnDiv.classList.add('grid-placeholder__loader-col')
        gridLoader.appendChild(columnDiv)
        animationTimeline.to(
          columnDiv,
          {
            height: '100%',
            duration: 4,
            ease: 'expo.inOut'
          },
          0.05 * i
        )
      }

      hasAnimatedRef.current = true // Mark as animated
    }
  }, [])

  return (
    <S.GridPlaceholder>
      <S.GridPlaceholderContainer>
        <S.GridPlaceholderLoader ref={gridLoaderRef} />
      </S.GridPlaceholderContainer>
    </S.GridPlaceholder>
  )
}

export default GridPlaceholder
